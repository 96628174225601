import React from 'react'

export default function Form() {
  return (
    <>
      <form className="form--element--container" name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <label for="name">Name</label>
        <br />
        <input type="text" name="name" placeholder="" />
        <br />
        <label for="email">Email</label>
        <br />
        <input type="email" name="email" placeholder="joe@bloggs.com" />
        <br />
        <label for="message">Message</label>
        <br />
        <textarea type="text" name="message" placeholder="Hi Craig, Lets collaborate"></textarea>
        <button type="submit" value="Submit message">SEND</button>
      </form>
    </>
  )
}


