import Form from './Form'

export default function Contact() {
  return (
    <section className="contact-form-container" id="contact">
      <div className="contact--info">
        <h1 className="contact--h1">CONTACT</h1>
        <p>Always remember in the jungle there’s a lot of they in there, after you overcome they, you will make it to paradise. They will try to close the door on you, just open it.</p>
      </div>

      <div className="contact--form">
        <Form />

      </div>



    </section>

  )
}