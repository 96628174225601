

export default function Tech() {
  return (
    <section className="tech--container" id="tech">
      <div className="tech--title">
        <h1 className="tech--h1">TECHNOLOGIES</h1>
      </div>
      <div className="tech--info">
        <p>Although React is my preferred JavaScript Library for development, I have experience using the below - some more than others but I'm always willing to jog my memory or learn something completely new</p>
      </div>

      <div className="tech--icon-container">

        <i class="devicon-html5-plain-wordmark"></i>
        <i class="devicon-css3-plain-wordmark"></i>
        <i class="devicon-javascript-plain"></i>
        <i class="devicon-react-original-wordmark"></i>
        <i class="devicon-tailwindcss-original-wordmark"></i>
        <i class="devicon-bulma-plain"></i>
        <i class="devicon-sass-original"></i>
        <i class="devicon-xd-line"></i>
        <i class="devicon-vscode-plain-wordmark"></i>
        <i class="devicon-mongodb-plain-wordmark"></i>
        <i class="devicon-firebase-plain-wordmark"></i>
        <i class="devicon-express-original-wordmark"></i>
        <i class="devicon-python-plain-wordmark"></i>
        <i class="devicon-django-plain"></i>
        <i class="devicon-postgresql-plain-wordmark"></i>
        <i class="devicon-github-original"></i>
        <i class="devicon-trello-plain-wordmark"></i>
        <i class="devicon-slack-plain-wordmark"></i>
        <i class="devicon-nodejs-plain-wordmark"></i>
        <i class="devicon-npm-original-wordmark"></i>
        <i class="devicon-webpack-plain-wordmark"></i>
        <i class="devicon-eleventy-plain"></i>
        <i class="devicon-jamstack-plain-wordmark"></i>
        <i class="devicon-jest-plain"></i>
        <i class="devicon-heroku-original-wordmark"></i>
      </div>
    </section>
  )
}