import React from 'react'

import Nav from './Nav'
import About from './About'
import Tech from './Tech'
import Projects from './Projects'
import Contact from './Contact'



function Main() {
  return (
    <>
      <Nav />
      <About />
      <Tech />
      <Projects />
      <Contact />
    </>
  )
}

export default Main
