import { Link } from 'react-scroll'



export default function Nav() {
  return (
    <header className="header--nav">
      <nav className="nav--container">
        <span className="nav--logo">CC</span>

        <ul className="nav--ul">
          <Link to="about" smooth={true} duration={2000}><li>ABOUT</li></Link>
          <Link to="tech" smooth={true} duration={2000}><li>TECH</li></Link>
          <Link to="projects" smooth={true} duration={2000}><li>PROJECTS</li></Link>
          <Link to="contact" smooth={true} duration={2000}><li>CONTACT</li></Link>

          <li><a href="https://github.com/CraigClem" target="_blank" rel="noreferrer"><i className="devicon-github-original"></i></a></li>
        </ul>
      </nav >
    </header >
  )
}