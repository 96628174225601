import React from 'react'
import Slider from 'react-slick'

import data from './data.json'

export default function Carousel() {

  const settings = {
    dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '50px',
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 2500
  };

  return (

    <Slider {...settings}>
      {data.map(project =>
        <div key={project.id} className="project--card">
          <a href={project.website} target="_blank" rel="noreferrer">
            <img src={`${project.image}`} alt={`${project.name}`} />
          </a>
          <div className="project--card--body">
            <h3 className="project--title">{project.title}</h3>
            <p className="project--info">{project.about}</p>
            <a href={`${project.github}`} target="_blank" rel="noreferrer">
              <i className="github-icon-card devicon-github-original-wordmark"></i>
            </a>
          </div>
        </div>
      )}
    </Slider>

  )
}

