import TypeWriterEffect from 'react-typewriter-effect';



export default function About() {
  return (
    <section className="about--container" id="about">
      <div className="animate-square"></div>
      <div className="about--info">
        <p><span>
          <TypeWriterEffect
            startDelay={200}
            cursorColor="black"
            text="Hi, I'm Craig..."
            typeSpeed={300}
          />
        </span>
          <br />
          <br />

          A Junior Front End Developer based in East London. A recent career changer and a graduate from a well respected Bootcamp now looking for a Front End role to continue my development and grow within a supportive team!
        </p>
      </div>
    </section>
  )
}