import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react'
import Main from './Main'



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
        </Routes >
      </Router >
    </>
  );
}

export default App;
